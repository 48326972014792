const baseUrl = "/cod";
const baseUrlFormat = `${baseUrl}/format`

export const POST_FORMAT = `${baseUrlFormat}`;

export const GET_BY_PROFORMAT_ID = `${baseUrlFormat}/by-proformatId`;

export const POST_VERSION_LIST = `${baseUrlFormat}/version-list`;

export const GET_OR_UPDATE_EXTERNAL_LIST = `${baseUrlFormat}/`;

export const GET_MASTER_LIST = `${baseUrlFormat}/get-masterlist`;

export const UPDATE_MASTER_LIST = `${baseUrlFormat}/`;

export const DOWNLOAD_COD = `${baseUrlFormat}/download`;

export const GET_BY_YEAR = `${baseUrl}/pro-format/year-list`;
