import { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import {
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
} from "@mui/material";
import * as url from "./utils/urlHelper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomTooltip from "components/@extended/Tooltip";
import { Edit } from "iconsax-react";
import withRouter from "components/withRouter";

const CORChildFormats = (props) => {
  const { navigate,params } = props?.router,
    { ApiServices,routeConstants } = props;
    const {proformatId}=params
  const [childData, setChildData] = useState({});
  const [versionData, setVersionData] = useState([]);

  const getByProformatId = async () => {
    try {
      const queryParams = new URLSearchParams({
        proformatId: proformatId,
      }).toString();
      let uri = url.GET_BY_PROFORMAT_ID + "?" + queryParams;
      let result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        let formatObj = {
          masterListOfRecord: null,
        };
        result?.data.forEach((item) => {
          if (item.documentName === "Master List of Quality Records") {
            formatObj.masterListOfRecord = item;
          }
        });
        setChildData(formatObj);
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA ERROR", error);
    }
  };

  useEffect(() => {
    getByProformatId();
  }, []);

  const getTitle = (title) => {
    return (
      <>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 1, pb: 0, fontSize: 20 }}
        >
          <h5>{title}</h5>
        </Stack>
      </>
    );
  };

  const handleAccordionChange = async (versionId) => {
    const reqObj = {
      versionId,
    };
    let uri = url.POST_VERSION_LIST;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    if (result?.meta?.success) {
      setVersionData(result?.data);
    }
  };

  return (
    <>
      <MainCard title={getTitle("COR Data")}>
        {Object.keys(childData)?.map((key) => (
          <Accordion
            key={key}
            onChange={() => handleAccordionChange(childData[key]?.versionId)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{childData[key]?.documentName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Document Number</TableCell>
                      <TableCell>Revision Number</TableCell>
                      <TableCell>Revision Date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {versionData?.length &&
                      versionData?.map((version) => (
                        <TableRow key={version?.revisionNumber}>
                          <TableCell>{version?.formatNumber}</TableCell>
                          <TableCell>{version?.revisionNumber}</TableCell>
                          <TableCell>
                            {/* {childData[key].versionData[version].revisionDate} */}
                          </TableCell>
                          <TableCell>
                            <CustomTooltip title="Edit">
                              <IconButton
                                color="primary"
                                onClick={(e) =>
                                  navigate(
                                    `/${routeConstants.COR}/master-List?documentId=${version?._id}`
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                            </CustomTooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </MainCard>
    </>
  );
};

CORChildFormats.propTypes = {};

export default withRouter(CORChildFormats);
