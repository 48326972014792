import { routeConstants } from "services/keyConstants";

let baseUrl = '/' + routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION;

export const ADD_DATA = `${baseUrl}/`;

export const CREATE_CUSTOMER_ORDER = `${baseUrl}/create-order-customer`;

export const GET_BY_ID = `${baseUrl}/`;

export const GET_ALL_DATA = `${baseUrl}/get-all`;

export const GET_ALL_DATA_BY_TYPE = `${baseUrl}/get-dynamic`;

export const GET_YEAR_LIST = `${baseUrl}/year-list`;

export const GET_ALL_PENDING = `${baseUrl}/get-all-pending`;

export const GET_ALL_DATA_BY_TYPE_SPECIFIC = `${baseUrl}/get-specific`;

export const UPDATE_DATA = `${baseUrl}/update-specific`;

export const UPDATE_DATA_MEDIA = `${baseUrl}/media`;

export const DOWNLOAD = `${baseUrl}/download`;
export const DOWNLOAD_AGREEMENT = `${baseUrl}/download-agreement`;
export const DOWNLOAD_List_OF_DISTRIBUTORS = `${baseUrl}/download-list-of-distributors`;

export const GET_BY_LINK = `${baseUrl}/get-by-link`;

let baseUrlProductHistory = `/product-history/`;
export const CREATE_PRODUCT_HISTORY = `${baseUrlProductHistory}/`;
