import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "./urlHelpers"
// render - pages
const ListOfCustomerProperty = Loadable(
  lazy(() => import("../ListOfCustomerProperty"))
);

const CustomerPropertyReportForm = Loadable(
  lazy(() => import("../CustomerPropertyReportForm"))
);

const ApprovalCustomerProperty = Loadable(
  lazy(() => import("../ApprovalCustomerProperty"))
);


// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

const CPRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.CONTROL_OF_CUSTOMER_PROPERTY}`, null, [
      createRoute("list-of-customer-property", <ListOfCustomerProperty />),
      createRoute(
        `customer-property-report/:id`,
        <CustomerPropertyReportForm />
      ),
      createRoute(
        "approval-property-report/:year",
        <ApprovalCustomerProperty />
      ),
    createRoute(
      commonRoutesConstants.YEAR_LIST,
      <CommonYearList
        uri={url.GET_BY_YEAR}
        isCreate={true}
        createRoute={{
          path: `/${routeConstants.CONTROL_OF_CUSTOMER_PROPERTY}/list-of-customer-property`,
        }}
        title={routeConstants.CONTROL_OF_CUSTOMER_PROPERTY}
        navigateToFolder={`/${routeConstants.CONTROL_OF_CUSTOMER_PROPERTY}/approval-property-report/`}
      />
    ),
    ]),
  ]
);

export default CPRoutes;
