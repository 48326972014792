import { ApiServices } from "services/apiServices";
import { Constants } from "services/keyConstants";
import { getItem, showToast } from "services/utils";
import * as url from "./urlHelper";

const userData = getItem(Constants.KEY_USER_DATA);

export const handleCreateCODFormat = async () => {
  try {
    const reqObj = {
      documentName: "Control Of Document",
      orgId: userData?.orgId,
      prefixType: "FormatPrefixes",
      year: new Date().getFullYear(),
    };
    const uri = url.POST_FORMAT;
    const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    showToast(result);
    return result
  } catch (error) {
    console.error("GET_FORMATS_BY_FORMATNAME ERROR", error);
  }
}
