import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

const IQAFormats = Loadable(lazy(() => import("../IQAFormats")));
const IQAHistory = Loadable(lazy(() => import("../IQAHistory")));
const IQAFormatDetails = Loadable(lazy(() => import("../IQAFormatDetails")));
const IQAChildFormats = Loadable(lazy(() => import("../IQAChildFormats")));
const InternalQualityAuditSchedule = Loadable(
  lazy(() => import("../InternalQualityAuditSchedule"))
);
const ListOfAuditors = Loadable(lazy(() => import("../ListOfAuditors")));
const AddEditInternalAuditPlan = Loadable(
  lazy(() => import("../AddEditInternalAuditPlan"))
);
const InternalAuditReport = Loadable(
  lazy(() => import("../InternalAuditReport"))
);
const CAR = Loadable(lazy(() => import("../CAR")));

const createRoute = (path, element, children) => ({ path, element, children });
// ==============================|| AUTH ROUTES ||============================== //

const IQARoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.IQA, null, [
      createRoute("iqaFormat", <IQAFormats />),
      createRoute("iqaFormatDetails", <IQAFormatDetails />),
      createRoute("iqaChildFormats", <IQAChildFormats />),
      createRoute("iqaHistory", <IQAHistory />),
      createRoute(
        "internalQualityAuditSchedule",
        <InternalQualityAuditSchedule />
      ),
      createRoute("listOfAuditors", <ListOfAuditors />),
      createRoute("addEditInternalAuditPlan", <AddEditInternalAuditPlan />),
      createRoute("internalAuditReport", <InternalAuditReport />),
      createRoute("car", <CAR />),
    ]),
  ]
);

export default IQARoutes;
