import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

const ProductRegister = Loadable(lazy(() => import("../ProductRegister")));
const ProductCodeRegister = Loadable(
  lazy(() => import("../ProductCodeRegister"))
);
const ProductHistoryRegister = Loadable(
  lazy(() => import("../ProductHistoryRegister"))
);

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const finishedProductRegisterRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.FINISHED_PRODUCT_REGISTER, null, [
      createRoute("view", <ProductRegister />),
      createRoute("view/:id", <ProductCodeRegister />),
      createRoute("view-table/:id/:childId", <ProductHistoryRegister />),
    ]),
  ]
);

export default finishedProductRegisterRoute;
