import { commonRoutesConstants, routeConstants } from "services/keyConstants";

const baseUrl = `/${routeConstants.CONTROL_OF_NON_CONFIRMING}`;

export const GET_ALL_DATA = `${baseUrl}/get-all`;

export const GET_ALL_DATA_BY_TYPE = `${baseUrl}/get-dynamic`;

export const GET_BY_FILTER = `${baseUrl}/get-by-filter`;

export const GET_ALL_DATA_BY_TYPE_SPECIFIC = `${baseUrl}/get-specific`;

export const UPDATE_DATA = `${baseUrl}/update-specific`;

export const GET_MATERIAL_LIST_DATA =
  "/monitoring-measurement-product/get-materialList";

export const GET_BY_YEAR = `${baseUrl}/${commonRoutesConstants.YEAR_LIST}`;

export const DOWNLOAD = `${baseUrl}/download`;
