import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import CORChildFormats from "../CORChildFormats";
import CORFormats from "../CORFormats";
import MasterListOfQualityRecords from "../MasterListOfQualityRecords";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "./urlHelper"
import { handleCreateCORFormat } from "./service";

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const CORRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.COR, null, [
      createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
        uri={url.GET_BY_YEAR}
        title={routeConstants.COR}
        isCreate={true}
        createRoute={{
          callExtraApi: handleCreateCORFormat
        }}
        isParam={true}
        paramName={"_id"}
        navigateToFolder={`/${routeConstants.COR}/cor-format/`}
      />),
      createRoute("cor-format/:year/:proformatId", <CORChildFormats />),
      createRoute("master-List", <MasterListOfQualityRecords />),
    ]),
  ]
);

export default CORRoutes;
