const { ApiServices } = require("services/apiServices");
import * as url from "./UrlHelper";

export const getData = async () => {
    try {
      const uri = url.GET_BY_YEAR
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        return result?.data;
      } 
    } catch (error) {
      console.error("error", error);
    } 
  };