import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import PreventiveMaintenanceList from "../PreventiveMaintenanceList";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "./urlHelper"
// Define lazy-loaded components
const PreventiveMachinePlan = Loadable(
  lazy(() => import("../PreventiveMachinePlan"))
);
const PreventiveMaintenanceReport = Loadable(
  lazy(() => import("../PreventiveMaintenanceReport"))
);
const MasterListOfMachine = Loadable(
  lazy(() => import("../MasterListOfMachine"))
);
const MachineBreakDown = Loadable(lazy(() => import("../MachineBreakDown")));
const MachineBreakDownList = Loadable(
  lazy(() => import("../MachineBreakDownList"))
);
const PreventiveMaintananceReportList = Loadable(
  lazy(() => import("../PreventiveMaintananceReportList"))
);
// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });
// Define routes using lazy-loaded components
const PreventiveMaintenanceRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.PREVENTIVE_MAINTAINCE}`, null, [
      createRoute("master-list-machine", <MasterListOfMachine />),
      createRoute("maintenance-plan", <PreventiveMachinePlan />),
      createRoute("machine-break-down", <MachineBreakDown />),
      createRoute("maintenance-report", <PreventiveMaintenanceReport />),
      createRoute(
        "maintenance-report-list/:year",
        <PreventiveMaintananceReportList />,
      ),
      createRoute("machine-break-downList/:year", <MachineBreakDownList />),
      createRoute("preventive-maintenance-list/:year", <PreventiveMaintenanceList />),
      createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
        uri={url.GET_YEAR}
        title={routeConstants.PREVENTIVE_MAINTAINCE}
        isExtraButtons={true}
        extraButtonChildren={
          [{ buttonTitle: "Create Maintenance Plan", path: `/${routeConstants.PREVENTIVE_MAINTAINCE}/maintenance-plan` },
          { buttonTitle: "Create Machine BreakDown", path: `/${routeConstants.PREVENTIVE_MAINTAINCE}/machine-break-down` },
          { buttonTitle: "Create Master List Of Machine", path: `/${routeConstants.PREVENTIVE_MAINTAINCE}/master-list-machine` },]
        }
        navigateToFolder={`/${routeConstants.PREVENTIVE_MAINTAINCE}/preventive-maintenance-list/`}
      />),
    ]),
  ]
);

export default PreventiveMaintenanceRoutes;
