import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// render - pages
const HeaderField = Loadable(lazy(() => import("../HeaderField")));
const Theme = Loadable(lazy(() => import("../Theme")));
const AssignedStandardList = Loadable(
  lazy(() => import("pages/standards/AssignedStandardList"))
);
const StandardDetails = Loadable(
  lazy(() => import("pages/standards/StandardDetails"))
);
const AddEditManual = Loadable(lazy(() => import("../AddEditManual")));
const Organogram = Loadable(lazy(() => import("../Organogram/Organogram")));
const InteractionOfProcess = Loadable(
  lazy(() => import("../InteractionOfProcess"))
);
const ListOfProcedure = Loadable(lazy(() => import("../ListOfProcedure")));
// quality manual
const YearList = Loadable(lazy(() => import("../../molecules/YearList")));
const List = Loadable(lazy(() => import("../../molecules/List")));
const Folder = Loadable(lazy(() => import("../../molecules/Folder")));
const Detail = Loadable(lazy(() => import("../../molecules/Detail")));

const createRoute = (path, element, children) => ({ path, element, children });

const QualityManualRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("standards", null, [
      createRoute("header-field", <HeaderField />),
      createRoute("themes", <Theme />),
      createRoute("standard-list", <AssignedStandardList />),
      createRoute("standard-details", <StandardDetails />),
      createRoute("add-edit-manual", <AddEditManual />),
      createRoute("organogram", <Organogram />),
      createRoute("interaction-of-process", <InteractionOfProcess />),
      createRoute("list-of-procedure", <ListOfProcedure />),
      createRoute(
        "quality-manual",
        <List
          moduleType="qualityManual"
          limit={10}
          name="Quality Manual"
          navigateUrl="quality-manual"
        />
      ),
      // get by year
      createRoute(
        "quality-manual-detail-year/:documentId/:type?",
        <YearList
          moduleType="qualityManual"
          name="Quality Manual"
          navigateUrl="quality-manual-detail"
        />
      ),
      // for document state folders
      createRoute(
        "quality-manual/:documentId",
        <Folder
          moduleType="qualityManual"
          name="Quality Manual"
          detailUrl="quality-manual-detail"
          yearUrl="quality-manual-detail-year"
        />
      ),
      // detail by id
      createRoute(
        "quality-manual-detail/:documentId/:type?/:year?",
        <Detail moduleType="qualityManual" name="Quality Manual" />
      ),
    ]),
  ]
);

export default QualityManualRoutes;
