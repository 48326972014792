import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { FormatConstants, routeConstants } from "services/keyConstants";
import { ConvertToKebabCase, kebabToNormal } from "services/utils";

const YearList = Loadable(lazy(() => import("../YearList")));
const FormatDetail = Loadable(lazy(() => import("../FormatDetail")));
const FormatDetailByType = Loadable(lazy(() => import("../FormatDetailByType")));

// Hiring
const CompetencyMatrix = Loadable(lazy(() => import("../Hiring/CompetencyMatrix")));
const InductionTrainingRecord = Loadable(
  lazy(() => import("../Hiring/InductionTrainingRecord"))
);
const ManPowerListWithSpecimenSignature = Loadable(
  lazy(() => import("../Hiring/ManPowerListWithSpecimenSignature"))
);
const RequisitionDetail = Loadable(
  lazy(() => import("../Hiring/RequisitionDetail"))
);


const TrainingNeedAssessment = Loadable(
  lazy(() => import("../Training/TrainingNeedAssessment"))
);
const TrainingCalender = Loadable(lazy(() => import("../Training/TrainingCalender")));
const TrainingAttendanceSheet = Loadable(
  lazy(() => import("../Training/TrainingAttendanceSheet"))
);
const TrainingEvaluationRevaluation = Loadable(
  lazy(() => import("../Training/TrainingEvaluationRevaluation"))
);
const TrainingEvaluationRevolutionFill = Loadable(
  lazy(() => import("../Training/TrainingEvaluationRevolutionFill"))
);

const createRoute = (path, element, children) => ({ path, element, children });

const CATRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.CAT, null, [
      createRoute("view/:type", <YearList />),
      createRoute(`${ConvertToKebabCase(FormatConstants.CAT.COMPETENCY_MATRIX)}/:year`, <CompetencyMatrix />),
      createRoute("list/:type", <YearList isFormat={true} name={kebabToNormal(routeConstants.CAT)} />),
      // format view
      createRoute("format-detail/:id/:year", <FormatDetail />),
      createRoute("format-detail-by-type/:type/:id/:year", <FormatDetailByType />),

      // Hiring

      // requisition for manpower
      createRoute("requisition-for-manpower-detail/:id/:year", <RequisitionDetail type="decision" />),
      createRoute("requisition-manpower", <RequisitionDetail type="new" />),
      // manpower list
      createRoute("manpower-list", <ManPowerListWithSpecimenSignature />),
      // induction-training-record
      createRoute("induction-training-record", <InductionTrainingRecord type="new" name="Induction Training Record" />),
      createRoute("induction-training-record-detail/:id/:year", <InductionTrainingRecord type="decision" name="Induction Training Record" />),

      // Training
      createRoute("training-assessment", <TrainingNeedAssessment />),
      createRoute("training-calendar/:year", <TrainingCalender />),
      createRoute("training-attendance", <TrainingAttendanceSheet />),
      createRoute(
        "training-evaluation-revaluation",
        <TrainingEvaluationRevaluation />
      ),
      createRoute(
        "training-evaluation-revaluation-fill",
        <TrainingEvaluationRevolutionFill />
      ),
    ]),
  ]
);

export default CATRoutes;
