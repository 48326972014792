import { routeConstants } from "services/keyConstants";

const BASE_URL = `${routeConstants.INSTALLATION}`
const baseUrlFormat = `${BASE_URL}/format`;

export const INSTALLATION_CREATE_FORMATS = `/${BASE_URL}/pro-format`;
export const INSTALLATION_FORMATS_LIST = `/${BASE_URL}/pro-format/list`;
export const INSTALLATION_FORMATS = `/${baseUrlFormat}`;
export const INSTALLATION_LIST = `/${baseUrlFormat}/by-proformatId`;
export const SENT_EMAIL = `/${baseUrlFormat}/email`;
export const ADD_UPDATE_INSTALLATION = `/${baseUrlFormat}/`;
export const GET_REPORT = `/${baseUrlFormat}/get-report`;
export const UPDATE_PROFORMAT = `/${BASE_URL}/pro-format`;
export const INSTALLATION_DOWNLOAD = `/${baseUrlFormat}/download`;
export const EXTERNAL_FORMAT_CREATE = `/${baseUrlFormat}/create`;
export const GET_BY_YEAR = `/${BASE_URL}/pro-format/year-list`;
