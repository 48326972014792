import { routeConstants } from "services/keyConstants";

let baseUrl = `/${routeConstants.CUSTOMER_COMPLAINTS}`;

export const CREATE_CUSTOMER_COMPLAINT = `${baseUrl}`;

export const GET_ALL_COMPLAINT = `${baseUrl}`;

export const UPDATE_SPECIFIC_COMPLAINT = `${baseUrl}/specific/`;

export const GET_ALL_COMPLAINTS_TO_ASSIGNED_USER = `${baseUrl}/list`;

export const UPDATE_SPECIFIC_COMPLAINT_WITH_IMAGE = `${baseUrl}/`;

export const DOWNLOAD_CC = `${baseUrl}/download`;

export const GET_BY_YEAR = `${baseUrl}/year-list`;

export const UPDATE_CA_IN_CC = `${baseUrl}/find-capa`;
