import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import MasterListOfDoc from "../MasterListOfDoc";
import ExternalListOfDoc from "../ExternalListOfDoc";
import CODChildFormats from "../CODChildFormats";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import * as url from "./urlHelper"
import CommonYearList from "components/reusables/CommonYearList";
import { handleCreateCODFormat } from "./service";

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const CODRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.COD, null, [
      createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
        uri={url.GET_BY_YEAR}
        title={routeConstants.CONTROL_OF_DOCUMENT}
        isCreate={true}
        createRoute={{
          callExtraApi: handleCreateCODFormat
        }}
        isParam={true}
        paramName={"_id"}
        navigateToFolder={`/${routeConstants.CONTROL_OF_DOCUMENT}/codFormat/`}
      />),
      createRoute("masterList", <MasterListOfDoc />),
      createRoute("externalList", <ExternalListOfDoc />),
      createRoute("codFormat/:year/:proformatId", <CODChildFormats />),
    ]),
  ]
);

export default CODRoutes;
