import React, { useEffect, useState } from "react";
import {
  Button,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
  TextField,
  Autocomplete,
} from "@mui/material";
import CustomDialog from "components/reusables/CustomDialog";
import { showToast } from "services/utils";
import withRouter from "./withRouter";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getUserDataByDesignation } from "services/organogramServices";

const CustomNotification = (props) => {
  const {
    open,
    setOpen,
    handleSubmit,
    ApiServices,
    commonUrl,
    isMultipleUser = true,
    isStaticDepartment = [],
    dialogTitle="",
    TypeConstants,
  } = props;
  const [data, setData] = useState([]);

  // Fetch data for users and departments
  const getData = async () => {
    try {
      if (isStaticDepartment?.length > 0) {
        const userDatas = await getUserDataByDesignation(
          [
            {
              type: TypeConstants.TYPE_FIND_ALL_USERS_BY_DEPARTMENT,
              department: isStaticDepartment,
            },
          ],
          false
        );

        setData(userDatas?.length > 0 ? userDatas : []);
      } else {
        const result = await ApiServices.callServiceGet(
          commonUrl?.GET_USER_DEPARTMENT_WISE
        );
        setData(result?.meta?.success ? result?.data : []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Formik setup with validation schema
  const formik = useFormik({
    initialValues: {
      selectedUser: [],
    },
    validationSchema: Yup.object({
      selectedUser: Yup.array()
        .min(1, "Please select at least one user")
        .required("User selection is required"),
    }),
    onSubmit: (values) => {
      if (values.selectedUser?.length > 0) {
        handleSubmit(values.selectedUser);
        handleClose();
      } else {
        showToast("Please select a user to notify");
      }
    },
  });

  // Close handler for the dialog
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setData([]);
  };

  return (
    <div>
      <CustomDialog open={open} onCancel={handleClose} maxWidth="sm">
        <DialogTitle>{dialogTitle===""?'Select Users For Notify':dialogTitle}</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                multiple={isMultipleUser}
                name="selectedUser"
                options={data}
                groupBy={(option) => option?.department}
                // getOptionLabel={(option) => option?.userDesignation}
                getOptionLabel={(option) => {
                  // Check if it's multiple users, if not show the 0th user
                  if (isMultipleUser) {
                    return option?.userDesignation; // Show designation for all users in multiple selection
                  } else {
                    return formik?.values?.selectedUser?.[0]
                      ? option?.userDesignation
                      : "" ?? ""; // Show designation for the first user when not multiple
                  }
                }}
                onChange={(event, value) =>
                  formik.setFieldValue(
                    "selectedUser",
                    isMultipleUser ? value : [value]
                  )
                }
                value={
                  isMultipleUser
                    ? formik?.values?.selectedUser
                    : formik?.values?.selectedUser?.[0]
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Users"
                    variant="outlined"
                    error={
                      formik.touched.selectedUser &&
                      Boolean(formik?.errors?.selectedUser)
                    }
                    helperText={
                      formik.touched.selectedUser && formik.errors.selectedUser
                    }
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  const { key, ...restProps } = props;
                  return (
                    <li key={key} {...restProps}>
                      {" "}
                      <Checkbox checked={selected} style={{ marginRight: 8 }} />
                      {option?.userDesignation}
                    </li>
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2.5 }}>
          <Button variant="contained" color="error" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};

export default withRouter(CustomNotification);
