import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import * as url from './urlHelper'
import CommonYearList from "components/reusables/CommonYearList";

// Define lazy-loaded components
const CorrectiveAction = Loadable(lazy(() => import("../CorrectiveAction")));
const PreventiveAction = Loadable(lazy(() => import("../PreventiveAction")));
const CAPAList = Loadable(lazy(() => import("../CapaList")));
const CAPAById = Loadable(lazy(() => import("../CAPAById")));
const YearList = Loadable(lazy(() => import("../YearList")));
const List = Loadable(lazy(() => import("../List")));
const CorrectiveActionList = Loadable(
  lazy(() => import("../CorrectiveActionList"))
);
const PreventiveActionList = Loadable(
  lazy(() => import("../PreventiveActionList"))
);

const getByType = (type) => {
  if (type === "CorrectiveAction") {
    return {
      uri: url.GET_BY_YEAR,
      createRoute: { path: `/capa/corrective/` },
      navigateToFolder: `/capa/corrective-action-list/`
    }
  } else if (type === "PreventiveAction") {
    return {
      uri: url.GET_BY_YEAR_PREVENTIVE_ACTION,
      createRoute: { path: `/capa/preventive` },
      navigateToFolder: `/capa/preventive-action-list/`
    }
  }
}

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const CAPARoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.CAPA, null, [
      createRoute("corrective", <CorrectiveAction />),
      createRoute("preventive", <PreventiveAction />),
      createRoute("corrective-action-list", <CorrectiveActionList />),
      createRoute("preventive-action-list", <PreventiveActionList />),
      createRoute("list", <List />),
      createRoute("capa", <CAPAList />),
      createRoute("capadetails", <CAPAById />),
      createRoute(commonRoutesConstants.YEAR_LIST + `/:type`, <CommonYearList
        title={routeConstants.CORRECTIVE_ACTION_PREVENTIVE_ACTION}
        isCreate={true}
        getByType={getByType}
      />),
    ]),
  ]
);

export default CAPARoutes;
