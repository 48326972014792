import {
  Box,
  Button,
  CardContent,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import CustomDialog from "components/reusables/CustomDialog";
import { Windows } from "iconsax-react";
import { useEffect, useRef, useState } from "react";
import {
  camelCaseToString,
  getUserData,
  sendNotification,
  showToast,
  getAllUserIds,
  getAllUserIdsForDepartment,
  getYearFromDate,
  isNotNullOrUndefined,
  extractUserDetails,
  filterUserById,
  getDocumentLifeCycleForMasterDoc,
} from "services/utils";
import { CheckCircleOutline, CloudUpload } from "@mui/icons-material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import copy from "copy-to-clipboard";
import EmailSelector from "components/EmailSelector";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import Waves from "assets/images/waves.svg";
import Dot from "components/@extended/Dot";
import { ADD_DATA } from "pages/CRPD/utils/urlHelpers";
import { getUserDataByDesignation } from "services/organogramServices";
import DynamicNotification from "components/DynamicNotification";
import FeedbackForm from "pages/Feedback/FeedbackForm";
import { ADD_FEEDBACK_DATA } from "pages/Feedback/utils/urlHelper";
import { CREATE_INTERNAL_COMMUNICATION } from "pages/Internal-Communication/utils/urlHelper";
import withRouter from "components/withRouter";
import {
  GET_ALL_CREATED_PROCEDURE_BY_ID,
  GET_FORMAT_LIST,
} from "services/url_helper";
import { routeConstants } from "services/keyConstants";

const MegaMenuSection = (props) => {
  const isSmallScreen = useMediaQuery("(min-width:1000px)");
  const {
      theme,
      userData,
      changeNoteData: changeNoteDataFromLocal,
      userRole,
      TypeConstants,
      DepartmentConstants,
      DesignationConstants,
      ApiServices,
      commonUrl,
      MasterDocumentTypeConstants,
      FormatConstants,
    } = props,
    { navigate } = props?.router,
    { ThemeMode, DRAWER_WIDTH } = props?.config;
  const [errors, setErrors] = useState();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const feedbackOptions = ["Internal", "External"];
  const internalCircularOptions = ["All Users", "Department", "Select Users"];
  const customerFeedbackOptions = [
    "Generate-Link",
    "Fill-Customer-Feedback",
    "Generate-Link-Invite",
  ];
  const [generateOrderData, setGenerateOrderData] = useState({
    email: "",
    mobile: "",
  });
  const [generateOrderDataError, setGenerateOrderDataError] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [userList, setUserList] = useState([]);
  const [allRoles, setAllRoles] = useState();
  const [designationUserList, setDesignationUserList] = useState([]);
  const [selectedModal, setSelectedModal] = useState("");
  const formikRef = useRef(null);
  const linkRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    type: "Internal",
    subType: "Generate-Link",
    message: "",
    selectedOption: "copyLink",
    userEmails: [],
    customerData: {
      name: "",
      email: "",
      address: "",
      mobile: "",
    },
    uploadedFile: null,
  });
  const [internalCircular, setInternalCircular] = useState({
    circularInitiator: { name: userData?.name, _id: userData?._id },
    department: userRole?.departmentName,
    date: new Date().toISOString().split("T")[0],
    selectType: "All Users",
    selectDepartment: "",
    communicatedTo: [],
    description: "",
  });
  const [internalCircularErrors, setInternalCircularErrors] = useState({});
  const [feedBackResponce, setFeedBackResponce] = useState(null);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [fileError, setFileError] = useState("");
  const [agentErrors, setAgentErrors] = useState({});
  const [agentData, setAgentData] = useState({
    name: "",
    address: "",
  });
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [filterDataBase, setFilterDataBase] = useState([]);

  const handleToggle = () => {
    if (!open) {
      getAllUserList();
    }
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleFeedbackModal = (e) => {
    setSelectedModal((prevValue) =>
      prevValue === "feedback" ? "" : "feedback"
    );
    setFeedbackData({
      type: "Internal",
      subType: "Generate-Link",
      message: "",
      selectedOption: "copyLink",
      userEmails: [],
      customerData: {
        name: "",
        email: "",
        address: "",
        mobile: "",
      },
      uploadedFile: null,
    });
    setFeedBackResponce(null);
    setCopied(false);
    setError(false);
  };

  const handleInternalCircularModal = () => {
    setSelectedModal((prevValue) =>
      prevValue === "internalCircular" ? "" : "internalCircular"
    );
    setInternalCircular({
      circularInitiator: { name: userData?.name, _id: userData?._id },
      department: userRole?.departmentName,
      date: new Date().toISOString().split("T")[0],
      selectType: "All Users",
      selectDepartment: "",
      communicatedTo: [],
      description: "",
    });
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      return "Email is required";
    } else if (!emailRegex.test(value)) {
      return "Invalid email address";
    }
    return "";
  };

  const handleGenerateOrderChange = (e) => {
    const { name, value } = e?.target;
    setGenerateOrderData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "email") {
      if (value !== "") {
        setGenerateOrderDataError(validateEmail(value));
      } else {
        setGenerateOrderDataError(true);
      }
    }
  };

  const handleSubmitOrder = async () => {
    if (generateOrderData?.email?.length === 0) {
      setGenerateOrderDataError(true);
      return;
    }
    const masterDocData = await getDocumentLifeCycleForMasterDoc(
      FormatConstants.CRPD.ORDER_FORM,
      MasterDocumentTypeConstants.FORMAT
    );
    try {
      const reqObj = {
        documentName: "Customer Form",
        isFormat: true,
        metaInfo: [
          {
            // order form
            masterDocId: masterDocData?._id,
            type: "Phase-0",
            createdByUser: userData?._id,
            customerData: generateOrderData,
            documentName: "Order Form",
            document: null,
            status: "initiated",
            senderUsers: [{ userId: userData?._id }],
            preparedBy: getUserData(userData),
            createdAt: new Date().toISOString(),
            notificationObj: {
              dataArray: [
                {
                  recipients: { userId: userData?._id },
                  msg: "Customer has fill order form. Please Check It.",
                  status: "New Order Request Has been arrived",
                  location: `customer-related-process-and-distribution/order-review/`,
                },
              ],
            },
          },
        ],
        orderStatus: "Pending",
        status: "initiated",
        history: [
          {
            documentName: "Customer Order Link Generated",
            type: "initiated",
            time: new Date().toISOString(),
            userData: getUserData(userData),
          },
        ],
        prefixType: "FormatPrefixes",
      };
      const result = await ApiServices.callServicePostWithBodyData(
        ADD_DATA,
        reqObj
      );
      if (result?.meta?.success) {
        showToast(result);
        setGenerateOrderDataError(false);
        setGenerateOrderData({ email: "", mobile: "" });
        handleGenerateOrderModal();
      } else {
        showToast(result);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleGenerateOrderModal = () => {
    setSelectedModal((prevValue) =>
      prevValue === "generateOrderForm" ? "" : "generateOrderForm"
    );
    setGenerateOrderData({
      email: "",
      mobile: "",
    });
  };

  const handleEmailsChange = (emails) => {
    setFeedbackData((prev) => ({
      ...prev,
      userEmails: emails,
    }));
    setEmailError(emails?.length === 0);
  };

  const mapDocuments = (documents) => {
    return documents?.map((doc) => {
      const { documentName, documentNumber, versionData } = doc;
      let isOriginal;
      if (isNotNullOrUndefined(versionData)) {
        isOriginal = Object.values(versionData)?.some(
          (version) => version?.documentState === "original"
        );
      }
      const documentState = isOriginal ? "original" : "Draft";
      return {
        documentName,
        documentNumber,
        documentState,
      };
    });
  };

  // getProcedureList remains the same
  const getProcedureList = async () => {
    const reqObj = {
      orgId: userData?.orgId,
    };
    let uri = GET_ALL_CREATED_PROCEDURE_BY_ID;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    if (result?.result?.type === "success") {
      return result?.result?.data?.procedures; // Return procedure data
    }
    return null; // Return null if API call fails
  };

  const getFormatList = async () => {
    let uri = GET_FORMAT_LIST;
    let result = await ApiServices.callServiceGet(uri);
    if (result?.response) {
      return result?.result; // Return procedure data
    }
    return null; // Return null if API call fails
  };

  const handleFeedbackChange = (e) => {
    feedbackData[e?.target?.name] = e?.target?.value;
    setFeedbackData((pre) => ({ ...pre }));
    setError(false);
    setEmailError(false);
    setFeedBackResponce(null);
    setCopied(false);
  };

  const handleInternalCircularChange = (e) => {
    const { name, value } = e.target;
    setInternalCircular((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear errors on input
    setInternalCircularErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name === "selectType" && value === "Select Users") {
      handleSelectUser("Select Users");
    }
    if (name === "selectType" && value === "Department") {
      handleSelectUser("Department");
    }
    if (name === "selectDepartment") {
      getUserNamesBySelectedDepartments(allRoles, e?.target?.value);
    }
    if (name === "communicatedTo") {
      getSelectedUserData(allRoles, e?.target?.value);
    }
  };

  const getSelectedUserData = (allRoles, selectedUsers) => {
    const selectedUserData = [];
    selectedUsers?.forEach((userString) => {
      const matchedUser = allRoles?.find(
        (role) => role?.userDesignation === userString
      );

      if (matchedUser) {
        selectedUserData?.push({
          userId: matchedUser?.userId,
          name: matchedUser?.userName || matchedUser?.name,
          designation: matchedUser?.designation,
          designationId: matchedUser?.designationId,
        });
      }
    });
    setDesignationUserList(selectedUserData);
  };

  const getUserNamesBySelectedDepartments = (data, selectedDepartments) => {
    const usersFromDepartments = data?.filter((user) =>
      selectedDepartments?.includes(user?.department)
    );
    setSelectedDepartments(selectedDepartments);
    const userDetails = usersFromDepartments?.map((user) => ({
      userId: user?.userId,
      name: user?.userName || user?.name,
      designation: user?.designation,
      designationId: user?.designationId,
    }));
    setDesignationUserList(userDetails);
  };

  const handleSelectUser = async (value) => {
    try {
      let result = await getUserDataByDesignation(
        [
          {
            type: TypeConstants?.TYPE_FIND_ALL_USERS,
          },
        ],
        false
      );
      if (result?.length > 0) {
        setAllRoles(result ?? []);
        if (value == "Select Users") {
          const excludeIds = [userData?._id];
          const namesArray = result
            ?.filter((user) => !excludeIds.includes(user?.userId))
            ?.map((user) => user?.userDesignation);
          setUserList(namesArray);
        } else if (value == "Department") {
          // fetch unique department
          const getUniqueDepartments = (data) => {
            const departmentSet = new Set(
              data?.map((item) => item?.department)
            );
            return Array.from(departmentSet);
          };
          setUserList(getUniqueDepartments(allRoles));
        }
      }
    } catch (error) {
      console.error("GET_ALL_ROLES_API ERROR", error);
    }
  };

  const validateInternalCircular = () => {
    let valid = true;
    const newErrors = {};

    // Required fields to validate
    const fieldsToValidate = [
      "circularInitiator.name", // Nested value check
      // "department",
      "date",
      "selectType",
      "description",
    ];

    // Check if each required field has a valid value
    fieldsToValidate?.forEach((field) => {
      const fieldValue = field.includes(".")
        ? field.split(".").reduce((acc, part) => acc?.[part], internalCircular)
        : internalCircular[field];

      if (
        !fieldValue ||
        (Array.isArray(fieldValue) && fieldValue.length === 0)
      ) {
        newErrors[field] = `${field.replace(/([A-Z])/g, " $1")} is required`;
        valid = false;
      }
    });

    // Conditional validation for 'Select Department' and 'Communicated To'
    if (
      internalCircular.selectType === "Department" &&
      !internalCircular.selectDepartment
    ) {
      newErrors.selectDepartment =
        "Department is required for 'Department' type";
      valid = false;
    }
    if (
      internalCircular.selectType === "Select Users" &&
      internalCircular.communicatedTo.length === 0
    ) {
      newErrors.communicatedTo =
        "'Communicated To' is required for 'Select Users' type";
      valid = false;
    }

    // Set errors in state if any
    setInternalCircularErrors(newErrors);
    return valid;
  };

  const handleInternalCircularSubmit = async () => {
    if (validateInternalCircular()) {
      const masterDocData = await getDocumentLifeCycleForMasterDoc(
        FormatConstants.INTERNAL_COMMUNICATION.INTERNAL_CIRCULAR,
        MasterDocumentTypeConstants.FORMAT
      );
      let reqObj = {
        orgId: userData?.orgId,
        clientUserId: userData?._id,
        documentName: "Internal Communication",
        prefixType: "FormatPrefixes",
        type: internalCircular?.selectType,
        date: internalCircular?.date,
        clientUserId: userData?._id,
        masterDocId: masterDocData?._id,
        document: {
          initiatedUserId: getUserData(userData),
          description: internalCircular?.description,
          ...(internalCircular?.selectType === "Department" && {
            departmentName: selectedDepartments,
          }),
          ...(internalCircular?.selectType === "All Users"
            ? { receiverUserData: extractUserDetails(allRoles) }
            : { receiverUserData: designationUserList }),
        },
      };
      let notification;
      if (internalCircular?.selectType == "All Users") {
        const excludeIds = [userData?._id];
        notification = getAllUserIds(allRoles, excludeIds);
      } else {
        notification = getAllUserIdsForDepartment(designationUserList);
      }
      let uri = CREATE_INTERNAL_COMMUNICATION;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        const reqObj = {
          dataArray: [
            {
              recipients: notification,
              status: "Internal Circular Filled By User",
              msg: "Internal Circular is submitted by user, please see it",
              location: `internal-communication/${result?.data?._id}`,
            },
          ],
        };
        await ApiServices.callServicePostWithBodyData(
          commonUrl?.GET_NOTIFICATIONS_QAHEAD,
          reqObj
        );
        setSelectedModal("");
        setInternalCircular({
          circularInitiator: { name: userData?.name, _id: userData?._id },
          department: userRole?.departmentName,
          date: new Date().toISOString().split("T")[0],
          selectType: "All Users",
          selectDepartment: "",
          communicatedTo: [],
          description: "",
        });
        setInternalCircularErrors({});
        window.dispatchEvent(new Event("internalCommunicationUpdate"));
      }
    }
  };
  const getAllUserList = async () => {
    try {
      const result = await ApiServices.callServiceGet(
        commonUrl?.GET_USER_DEPARTMENT_WISE
      );
      setAllRoles(result?.meta?.success ? result?.data : []);
    } catch (error) {
      console.error("GET_ALL_ROLES_API ERROR", error);
    }
  };

  // feedback submit handler
  const handleSubmitFeedback = async () => {
    try {
      if (feedbackData?.type === "External") {
        if (feedbackData?.subType === "Fill-Customer-Feedback") {
          if (formikRef.current && formikRef.current.submitForm) {
            formikRef.current.submitForm();
          }
        } else if (
          feedbackData?.subType === "Generate-Link-Invite" &&
          feedbackData?.userEmails?.length === 0
        ) {
          setEmailError(true);
          return;
        }
      }

      if (feedbackData?.type === "Internal" && !feedbackData?.message?.trim()) {
        setError(true);
        return;
      }
      let notificationArr = [];
      if (feedbackData?.type === "Internal") {
        // fetch user
        let userDatas = await getUserDataByDesignation(
          [
            {
              type: TypeConstants.TYPE_FIND_ALL_USERS_BY_DESIGNATION,
              designations: [
                DesignationConstants.MANAGING_DIRECTOR,
                DesignationConstants.DIRECTOR,
              ],
            },
          ],
          false
        );
        notificationArr.push(...userDatas);
      } else if (feedbackData?.type === "External") {
        // fetch user
        let userDatas = await getUserDataByDesignation(
          [
            {
              type: TypeConstants.TYPE_FIND_ALL_USERS_BY_DESIGNATION,
              designations: [
                DesignationConstants.MANAGING_DIRECTOR,
                DesignationConstants.DIRECTOR,
              ],
            },
            {
              type: TypeConstants.TYPE_FIND_HEAD,
              department: [DepartmentConstants.SALES_AND_MARKETING_DEPARTMENT],
            },
          ],
          false
        );
        notificationArr.push(...userDatas);
      }
      let tempObj =
        feedbackData?.type === "Internal"
          ? {
              dataArray: [
                {
                  recipients: notificationArr?.map((user) => ({
                    userId: user?.userId,
                  })),
                  status: "Feedback Form Filled By User",
                  msg: "Feedback form submitted by user,please Review it",
                  location: `feedback/view/${getYearFromDate(new Date())}`,
                },
              ],
            }
          : feedbackData?.type === "External" &&
            feedbackData?.subType === "Fill-Customer-Feedback" && {
              dataArray: [
                {
                  recipients: notificationArr?.map((user) => ({
                    userId: user?.userId,
                  })),
                  status: "Feedback Form Filled By User",
                  msg: "Feedback form submitted by user,please Review it",
                  location: `feedback/view/${getYearFromDate(new Date())}`,
                },
              ],
            };

      // find masterDocData for _id
      let masterDocData = await getDocumentLifeCycleForMasterDoc(
        "Feedback",
        MasterDocumentTypeConstants.FORMAT
      );

      let reqObj = {
        orgId: userData?.orgId,
        clientUserId: userData?._id,
        masterDocId: masterDocData?._id,
        formatName: "Feedback",
        documentName: "Feedback",
        prefixType: "FormatPrefixes",
        type: feedbackData?.type,
        subType:
          feedbackData?.type === "External"
            ? ["Generate-Link", "Generate-Link-Invite"].includes(
                feedbackData?.subType
              )
              ? "Generate-Link"
              : feedbackData?.subType
            : "",
        document: {
          userData: getUserData(userData),
          ...(feedbackData?.type === "Internal" && {
            message: feedbackData?.message,
            notificationUsers: notificationArr,
          }),
          ...(feedbackData?.type === "External" &&
            feedbackData?.subType === "Fill-Customer-Feedback" && {
              feedbackData: {
                status: {
                  createdAt: null,
                  comment: null,
                  currentStatus: "Pending",
                  clientUserId: null,
                },
                ...formikRef.current.feedbackCustomerData.values,
              },
              notificationUsers: notificationArr,
            }),
          ...(feedbackData?.type === "External" &&
            ["Generate-Link", "Generate-Link-Invite"].includes(
              feedbackData?.subType
            ) && {
              notificationData: {
                recipients: notificationArr?.map((user) => ({
                  userId: user?.userId,
                })),
                status: "Feedback Form Filled By User",
                msg: "Feedback form submitted by user,please Review it",
                location: `feedback/`,
              },
              responces: [],
              domainName: `${window.location.origin}/feedback-guest/`,
              userEmails:
                feedbackData?.subType === "Generate-Link-Invite"
                  ? feedbackData?.userEmails
                  : undefined,
            }),
        },
      };
      let formatResult, resultObj;
      if (
        feedbackData?.type === "External" &&
        feedbackData?.subType === "Fill-Customer-Feedback"
      ) {
        if (
          formikRef.current?.feedbackCustomerData?.dirty &&
          formikRef.current?.feedbackCustomerData?.isValid
        ) {
          const formData = new FormData();
          formData.append("data", JSON.stringify(reqObj));
          formatResult = await ApiServices.callServicePostWithFormData(
            ADD_FEEDBACK_DATA,
            formData
          );
          if (formatResult?.meta?.success) {
            setSelectedModal("");
            setFeedBackResponce(null);
            showToast(formatResult);
            tempObj.dataArray[0].location = `feedback/${formatResult?.data?._id}`;
            await sendNotification(tempObj);
            window.dispatchEvent(new Event("feedbackUpdate"));
          } else {
            showToast(formatResult);
          }
        }
      } else if (
        feedbackData?.type === "External" &&
        feedbackData?.subType !== "Fill-Customer-Feedback"
      ) {
        const formData = new FormData();
        formData.append("data", JSON.stringify(reqObj));
        formatResult = await ApiServices.callServicePostWithFormData(
          ADD_FEEDBACK_DATA,
          formData
        );
        if (formatResult?.meta?.success) {
          showToast(formatResult);
          resultObj = {
            link: formatResult?.data?.document?.domainLink ?? "-",
          };
          if (feedbackData?.subType === "Generate-Link-Invite") {
            setSelectedModal("");
          }
          setFeedBackResponce(resultObj);
          window.dispatchEvent(new Event("feedbackUpdate"));
        } else {
          showToast(formatResult);
        }
      } else {
        const formData = new FormData();
        formData.append("data", JSON.stringify(reqObj));
        feedbackData?.uploadedFile?.file !== undefined &&
          formData.append("images", feedbackData?.uploadedFile?.file);
        formatResult = await ApiServices.callServicePostWithFormData(
          ADD_FEEDBACK_DATA,
          formData
        );

        if (formatResult?.meta?.success) {
          setSelectedModal("");
          setFeedBackResponce(null);
          showToast(formatResult);
          tempObj.dataArray[0].location = `feedback/${formatResult?.data?._id}`;
          await sendNotification(tempObj);
          window.dispatchEvent(new Event("feedbackUpdate"));
        } else {
          showToast(formatResult);
        }
      }
    } catch (error) {
      console.error("CREATE_FORMATS ERROR", error);
    }
  };

  // for copy link
  const copyToClipboard = () => {
    const linkText = linkRef.current?.textContent;
    if (copy(linkText)) {
      showToast("Linked Copied Successfully", true);
      setCopied(true);
      setError(false);
      setEmailError(false);
    } else {
      showToast("Error in copying link");
      setError(false);
      setEmailError(false);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files?.[0]; // Single file with optional chaining
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
    ];

    if (!allowedTypes.includes(file?.type)) {
      setFileError(
        "Only PDF, Word documents, and image files (JPEG, PNG) are allowed."
      );
    } else {
      setFileError("");
      const preview = file?.type?.startsWith("image/")
        ? URL.createObjectURL(file)
        : null;
      setFeedbackData((prevState) => ({
        ...prevState,
        uploadedFile: { file, preview },
      }));
    }
  };

  // Function to handle opening/closing the modal
  const handleAgentModal = () => {
    setSelectedModal((prevValue) => (prevValue === "agent" ? "" : "agent"));
    setAgentData({
      name: "",
      address: "",
    });
    setAgentErrors({});
  };

  // Function to handle changes to the agent form inputs
  const handleAgentChange = (e) => {
    const { name, value } = e.target;
    setAgentData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear errors on input change
    setAgentErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  // agent modal handler
  const handleAgentSubmit = async () => {
    const masterDocData = await getDocumentLifeCycleForMasterDoc(
      FormatConstants.CRPD.AGENT_HISTORY,
      MasterDocumentTypeConstants.FORMAT
    );
    const errors = {
      name: agentData?.name ? "" : "Name is required",
      address: agentData?.address ? "" : "Address is required",
    };

    const filteredErrors = Object.fromEntries(
      Object.entries(errors).filter(([_, v]) => v)
    );

    if (Object.keys(filteredErrors).length) {
      return setAgentErrors(filteredErrors);
    }

    // fetch user
    let headUser = await getUserDataByDesignation(
      [
        {
          type: TypeConstants.TYPE_FIND_HEAD,
          department: [DepartmentConstants.SALES_AND_MARKETING_DEPARTMENT],
        },
      ],
      false
    );
    const reqObj = {
      document: {
        ...agentData,
        createdBy: getUserData(userData),
        approveBy: extractUserDetails(headUser),
      },
      masterDocId: masterDocData?._id,
      status: "initiated",
      documentSubmitted: false,
      history: [
        {
          documentName: "Agent Created",
          type: "initiated",
          time: new Date().toISOString(),
          userData: getUserData(userData),
        },
      ],
      documentName: "Agent Data",
      prefixType: "FormatPrefixes",
    };

    const result = await ApiServices.callServicePostWithBodyData(
      ADD_DATA,
      reqObj
    );

    showToast(result);
    if (result?.meta?.success) {
      handleAgentModal();
      let notificationObj = {
        dataArray: [
          {
            recipients: filterUserById(headUser),
            msg: "A new agent request has been received. Please make a decision.",
            status: "New agent request received.",
            location: `customer-related-process-and-distribution/agreement/${result?.data?._id}`,
          },
        ],
      };
      await sendNotification(notificationObj);
      window.dispatchEvent(new Event("agentDataUpdate"));
    }
  };

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "secondary.200" : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  const handleService = () => {
    navigate(`${routeConstants.CUSTOMER_SERVICE}/customer-service-form`);
  };

  const buttonData = {
    general: [
      {
        text: "Customer Service Form",
        onClick: handleService,
      },
      {
        text: "Feedback",
        onClick: handleFeedbackModal,
      },
      {
        text: "Internal communication",
        onClick: handleInternalCircularModal,
      },
      {
        text: "Customer Property",
        onClick: () =>
          navigate(
            `${routeConstants.CONTROL_OF_CUSTOMER_PROPERTY}/list-of-customer-property`
          ),
      },
      {
        text: "Work Environment",
        onClick: () => navigate("work-environment/environment"),
      },
      {
        text: "Add-Complaint",
        onClick: () =>
          navigate(`${routeConstants.CUSTOMER_COMPLAINTS}/add-complaint`),
      },
      {
        text: "Generate Inprocess Record",
        onClick: () => navigate(`${routeConstants.MAM_OF_PRODUCT}/incoming`),
      },
      {
        text: "Change Note",
        onClick: () => navigate(`${routeConstants.CHANGE_NOTE}/manage`),
      },
      {
        text: "Communication With RegulatoryBody",
        onClick: () =>
          navigate(
            `/${routeConstants.COMMUNICATION_WITH_REGULATORY_BODIES}/communication-with-regulatory-body`
          ),
      },
    ],
    CAPA: [
      {
        text: "Corrective action",
        onClick: () => navigate("capa/corrective"),
      },
      {
        text: "Preventive Action",
        onClick: () => navigate("capa/preventive"),
      },
    ],
    customerRelatedProcessAndDistribution: [
      {
        text: "Generate New Agent",
        onClick: handleAgentModal,
      },
      {
        text: "Generate Order Form",
        onClick: () => handleGenerateOrderModal(),
      },
    ],
  };

  // Filter out falsy values for each key in buttonData
  Object.keys(buttonData)?.forEach((key) => {
    buttonData[key] = buttonData[key]?.filter(Boolean);

    // If no valid buttons, delete the key
    if (buttonData[key]?.length === 0) {
      delete buttonData[key];
    }
  });

  const createButton = (text, onClick, gradient1, gradient2, shadow) => ({
    text,
    onClick,
  });

  const createButtonsForCategory = (category) => {
    return (
      buttonData[category]
        ?.filter(Boolean)
        ?.map(({ text, onClick }) => createButton(text, onClick)) || []
    );
  };

  useEffect(() => {
    const handleUpdateData = () => {
      handleFeedbackModal();
    };

    window.addEventListener("createFeedback", handleUpdateData);

    return () => {
      window.removeEventListener("createFeedback", handleUpdateData);
    };
  }, []);

  useEffect(() => {
    const handleUpdateData = () => {
      handleInternalCircularModal();
    };

    window.addEventListener("createInternalCommunication", handleUpdateData);

    return () => {
      window.removeEventListener(
        "createInternalCommunication",
        handleUpdateData
      );
    };
  }, []);

  useEffect(() => {
    const handleUpdateData = () => {
      handleAgentModal();
    };

    window.addEventListener("agentModalOpen", handleUpdateData);

    return () => {
      window.removeEventListener("agentModalOpen", handleUpdateData);
    };
  }, []);

  useEffect(() => {
    const handleUpdateData = () => {
      setSelectedModal("customer-servicing-record");
    };

    window.addEventListener("customer-servicing-record", handleUpdateData);

    return () => {
      window.removeEventListener("customer-servicing-record", handleUpdateData);
    };
  }, []);

  // Function to clear error when a field is changed
  const clearError = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  return (
    <>
      {isOpenNotification ? (
        <DynamicNotification
          open={isOpenNotification}
          setOpen={setIsOpenNotification}
          isMultipleDepartment={false}
          isMultipleUser={false}
          handleSubmit={handleSubmitMasterList}
        />
      ) : null}
      <Box sx={{ flexShrink: 0, ml: 0.5 }}>
        <IconButton
          color="secondary"
          variant="light"
          aria-label="open Btns"
          ref={anchorRef}
          aria-controls={open ? "Btns-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size="large"
          sx={{
            color: "secondary.main",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            p: 1,
          }}
        >
          <Windows variant="Bulk" />
        </IconButton>

        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: { offset: isSmallScreen ? [170, 9] : [0, 9] },
              },
            ],
          }}
          sx={{
            maxHeight: "500px",
            overflow: "hidden",
          }}
        >
          {({ TransitionProps }) => (
            <Transitions
              type="grow"
              position="top-right"
              in={open}
              {...TransitionProps}
            >
              {isSmallScreen ? (
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: {
                      md: `calc(100vw - 200px)`,
                      lg: `calc(100vw - ${DRAWER_WIDTH + 100}px)`,
                      xl: `calc(100vw - ${DRAWER_WIDTH + 140}px)`,
                    },
                    maxWidth: 1024,
                    maxHeight: "500px",
                    borderRadius: 1.5,
                    overflow: "hidden",
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard elevation={0} border={false} content={false}>
                      <Grid container>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            color: "common.white",
                            bgcolor:
                              theme?.palette?.mode === ThemeMode?.DARK
                                ? "primary.400"
                                : "primary.darker",
                            position: "relative",
                            "&:after": {
                              content: '""',
                              background: `url("${Waves}") 100% / cover no-repeat`,
                              position: "absolute",
                              top: "41%",
                              left: 0,
                              right: 0,
                              bottom: 0,
                              zIndex: 1,
                              opacity: 0.5,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              p: 4.5,
                              pb: 3,
                              position: "inherit",
                              zIndex: 2,
                            }}
                          >
                            <Stack sx={{ color: "background.paper" }}>
                              <Typography
                                variant="h2"
                                sx={{ fontSize: "1.875rem", mb: 1 }}
                              >
                                Explore Features
                              </Typography>
                              <Typography variant="h6">
                                Explore our features to kickstart your work."
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                        <Grid item xs={8}>
                          <Box
                            sx={{
                              p: 4,
                              "& .MuiList-root": { pb: 0 },
                              "& .MuiListSubheader-root": { p: 0, pb: 1.5 },
                              "& .MuiListItemButton-root": {
                                p: 0.5,
                                "&:hover": {
                                  bgcolor: "transparent",
                                  "& .MuiTypography-root": {
                                    color: "primary.main",
                                  },
                                },
                              },
                              "& .MuiListItemIcon-root": { minWidth: 16 },
                            }}
                          >
                            <Grid
                              container
                              spacing={6}
                              sx={{
                                overflowY: "scroll",
                                maxHeight: "500px",
                                "&::-webkit-scrollbar": {
                                  width: "5px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: "10px",
                                },
                                "&::-webkit-scrollbar-track": {
                                  backgroundColor:
                                    theme.palette.background.paper,
                                },
                              }}
                            >
                              {Object?.keys(buttonData)?.map((category) => (
                                <Grid item xs={6} key={category}>
                                  <List
                                    component="nav"
                                    aria-labelledby={`nested-list-${category}`}
                                    subheader={
                                      <ListSubheader
                                        id={`nested-list-${category}`}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          color="text.primary"
                                          sx={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {camelCaseToString(category)}
                                        </Typography>
                                      </ListSubheader>
                                    }
                                  >
                                    {createButtonsForCategory(category)
                                      ?.length > 0
                                      ? createButtonsForCategory(category)?.map(
                                          (button, i) => (
                                            <ListItemButton
                                              key={i}
                                              onClick={(e) => {
                                                if (button?.onClick)
                                                  button.onClick(e);
                                                handleClose(e);
                                              }}
                                            >
                                              <ListItemIcon>
                                                <Dot
                                                  size={6}
                                                  color="secondary"
                                                  variant="outlined"
                                                />
                                              </ListItemIcon>
                                              <ListItemText
                                                primary={button?.text}
                                              />
                                            </ListItemButton>
                                          )
                                        )
                                      : null}
                                  </List>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              ) : (
                <Paper
                  sx={{
                    marginLeft: 2,
                    marginRight: 2,
                    boxShadow: theme.customShadows.z1,
                    maxWidth: 350,
                    overflowY: "scroll",
                    maxHeight: "500px",
                    borderRadius: 1.5,
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard border={false} content={false}>
                      <CardContent sx={{ p: 2, pb: 0 }}>
                        {Object.keys(buttonData).map((category) => (
                          <Box key={category} sx={{ mb: 1 }}>
                            <Typography
                              variant="subtitle2"
                              color="text.primary"
                              sx={{
                                textTransform: "capitalize",
                                fontSize: "11px",
                                // mb: 1,
                              }}
                            >
                              {camelCaseToString(category)}
                            </Typography>
                            <Divider />
                            <List
                              component="nav"
                              sx={{
                                p: 0,
                                "& .MuiListItemIcon-root": { minWidth: 32 },
                                "& .MuiDivider-root": { my: 1 },
                              }}
                            >
                              {createButtonsForCategory(category)?.length > 0 &&
                                createButtonsForCategory(category)?.map(
                                  ({ text, onClick }, i) => (
                                    <ListItemButton
                                      key={i}
                                      onClick={(e) => {
                                        if (onClick) onClick(e);
                                        handleClose(e);
                                      }}
                                    >
                                      <ListItemText primary={text} />
                                    </ListItemButton>
                                  )
                                )}
                            </List>
                          </Box>
                        ))}
                      </CardContent>
                      <Divider />
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper>
      </Box>

      {selectedModal === "feedback" && (
        <CustomDialog
          open={selectedModal}
          onCancel={(e) => handleFeedbackModal(e)}
          maxWidth={"md"}
        >
          <DialogTitle>Feedback</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      marginBottom: "0.5rem",
                    }}
                  >
                    Feedback Type
                  </InputLabel>
                  <Select
                    placeholder="Select Standard"
                    name="type"
                    value={feedbackData?.type}
                    onChange={(e) => {
                      handleFeedbackChange(e);
                      setFeedbackData({
                        ...feedbackData,
                        userEmails: [],
                        uploadedFile: null,
                      });
                    }}
                  >
                    {feedbackOptions.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {feedbackData?.type === "Internal" && (
                <>
                  <Grid item xs={12} md={12}>
                    <TextField
                      multiline
                      rows={4}
                      fullWidth
                      label="Write feedback"
                      name="message"
                      error={error}
                      helperText={error ? "Feedback message is required." : ""}
                      value={feedbackData?.message}
                      onChange={(e) => handleFeedbackChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <input
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      type="file"
                      onChange={handleFileUpload}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUpload />}
                      >
                        Upload Documents
                      </Button>
                    </label>
                    {fileError && (
                      <Typography
                        sx={{ marginLeft: "2rem" }}
                        variant="body2"
                        color="error"
                      >
                        {fileError}
                      </Typography>
                    )}

                    {feedbackData?.uploadedFile !== null && (
                      <div style={{ marginTop: "10px" }}>
                        <Typography variant="body1">
                          File uploaded:{" "}
                          {feedbackData?.uploadedFile?.file?.name}
                        </Typography>
                        <div>
                          <img
                            src={feedbackData?.uploadedFile?.preview}
                            alt="preview"
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Grid>
                </>
              )}

              {feedbackData?.type === "External" && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        sx={{
                          marginBottom: "0.5rem",
                        }}
                      >
                        Customer Feedback Type
                      </InputLabel>
                      <Select
                        placeholder="Select SubType"
                        name="subType"
                        value={feedbackData?.subType}
                        onChange={(e) => handleFeedbackChange(e)}
                      >
                        {customerFeedbackOptions.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {feedbackData?.subType === "Generate-Link" &&
                    feedBackResponce?.link?.length > 0 && (
                      <div>
                        <span ref={linkRef} style={{ display: "none" }}>
                          {feedBackResponce.link}
                        </span>
                        <Button
                          variant="contained"
                          color={copied ? "success" : "primary"}
                          sx={{ margin: "1rem 1.5rem" }}
                          onClick={copyToClipboard}
                          endIcon={
                            copied ? (
                              <CheckCircleOutline />
                            ) : (
                              <ContentCopyOutlinedIcon />
                            )
                          }
                        >
                          {copied ? "Copied" : "Copy Link"}
                        </Button>
                      </div>
                    )}

                  {feedbackData?.subType === "Generate-Link-Invite" && (
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <EmailSelector
                          emails={feedbackData?.userEmails}
                          setEmails={handleEmailsChange}
                        />
                        {emailError && (
                          <span
                            style={{
                              color: "red",
                              marginTop: "0.5rem",
                              display: "block",
                            }}
                          >
                            Email is required.
                          </span>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  {feedbackData?.subType === "Fill-Customer-Feedback" && (
                    <Grid item xs={12}>
                      <FeedbackForm privateRoute={true} formikRef={formikRef} />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </DialogContent>
          <Divider />

          <DialogActions sx={{ p: 2.5 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                color="error"
                variant="contained"
                onClick={(e) => handleFeedbackModal(e)}
              >
                Cancel
              </Button>
              {feedBackResponce === null && (
                <Button
                  color="success"
                  variant="contained"
                  type="submit"
                  onClick={() => handleSubmitFeedback()}
                >
                  Submit
                </Button>
              )}
            </Stack>
          </DialogActions>
        </CustomDialog>
      )}
      {selectedModal === "internalCircular" && (
        <CustomDialog
          open={selectedModal}
          onCancel={(e) => handleInternalCircularModal(e)}
          maxWidth={"md"}
        >
          <DialogTitle>Internal Circular</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              {[
                {
                  label: "Circular Initiator",
                  name: "circularInitiator.name",
                  isReadOnly: true,
                },
                { label: "Department", name: "department", isReadOnly: true },
                {
                  label: "Date",
                  name: "date",
                  type: "date",
                  InputLabelProps: { shrink: true },
                  isReadOnly: true,
                },
                {
                  label: "Select Type",
                  name: "selectType",
                  type: "select",
                  options: internalCircularOptions,
                },
                {
                  label: "Select Department",
                  name: "selectDepartment",
                  type: "select",
                  options: userList,
                  isMulti: true,
                  hidden: internalCircular?.selectType !== "Department",
                },
                {
                  label: "Communicated To",
                  name: "communicatedTo",
                  type: "select",
                  isMulti: true,
                  options: userList,
                  value: internalCircular?.communicatedTo || [],
                  hidden: internalCircular?.selectType !== "Select Users",
                },
              ].map((field, index) => {
                // Handle value extraction for single and multi-selects
                const value =
                  field.type === "select" && field.isMulti
                    ? Array.isArray(internalCircular[field.name])
                      ? internalCircular[field.name]
                      : []
                    : field.name.includes(".")
                    ? field.name
                        .split(".")
                        .reduce((acc, part) => acc?.[part], internalCircular)
                    : internalCircular[field.name];

                // Handle error extraction
                const error = field.name.includes(".")
                  ? field.name
                      .split(".")
                      .reduce(
                        (acc, part) => acc?.[part],
                        internalCircularErrors
                      )
                  : internalCircularErrors[field.name];

                return (
                  !field.hidden && (
                    <Grid item xs={12} sm={6} key={index}>
                      {field.type === "select" ? (
                        <FormControl
                          fullWidth
                          error={!!internalCircularErrors[field.name]}
                        >
                          <InputLabel sx={{ marginBottom: "0.5rem" }}>
                            {field.label}
                          </InputLabel>
                          <Select
                            name={field?.name}
                            value={value}
                            multiple={field?.isMulti}
                            disabled={field?.isReadOnly}
                            onChange={handleInternalCircularChange}
                          >
                            {field?.options?.map((option, idx) => (
                              <MenuItem
                                key={idx}
                                value={option?.value || option}
                              >
                                {option?.label || option}
                              </MenuItem>
                            ))}
                          </Select>
                          {!!error && <FormHelperText>{error}</FormHelperText>}
                        </FormControl>
                      ) : (
                        <TextField
                          fullWidth
                          label={field?.label}
                          name={field?.name}
                          type={field?.type || "text"}
                          InputLabelProps={field?.InputLabelProps}
                          value={value}
                          onChange={handleInternalCircularChange}
                          error={!!error}
                          helperText={error}
                          required
                          disabled={field?.isReadOnly}
                        />
                      )}
                    </Grid>
                  )
                );
              })}

              <Grid item xs={12}>
                <Typography mb={2} variant="subtitle1">
                  Description:
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  name="description"
                  value={internalCircular.description}
                  onChange={handleInternalCircularChange}
                  error={!!internalCircularErrors.description}
                  helperText={internalCircularErrors.description}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />

          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={(e) => handleInternalCircularModal(e)}
                  >
                    Cancel
                  </Button>
                  {feedBackResponce === null && (
                    <Button
                      color="success"
                      variant="contained"
                      type="submit"
                      onClick={() => handleInternalCircularSubmit()}
                    >
                      Submit
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </CustomDialog>
      )}
      {selectedModal === "agent" && (
        <CustomDialog
          open={selectedModal}
          onCancel={handleAgentModal}
          maxWidth={"md"}
        >
          <DialogTitle>Agent Information</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <Grid container spacing={2}>
              {[
                {
                  label: "Name",
                  name: "name",
                },
                {
                  label: "Address",
                  name: "address",
                },
              ].map((field, index) => {
                // Handle value extraction
                const value = agentData[field.name];

                // Handle error extraction
                const error = agentErrors[field.name];

                return (
                  <Grid item xs={12} sm={6} key={index}>
                    <TextField
                      fullWidth
                      label={field?.label}
                      name={field?.name}
                      value={value ?? ""}
                      onChange={handleAgentChange}
                      error={!!error}
                      helperText={error}
                      required
                    />
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
          <Divider />

          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleAgentModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    color="success"
                    variant="contained"
                    type="submit"
                    onClick={handleAgentSubmit}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </CustomDialog>
      )}
      {selectedModal === "generateOrderForm" && (
        <CustomDialog
          open={selectedModal}
          onCancel={(e) => handleGenerateOrderModal(e)}
          maxWidth={"md"}
        >
          <DialogTitle>Generate Order For Customer</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={generateOrderData?.email}
                  onChange={handleGenerateOrderChange}
                  required
                  error={generateOrderDataError}
                  helperText={
                    generateOrderDataError ? "Please enter a valid email" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Mobile"
                  name="mobile"
                  value={generateOrderData?.mobile}
                  onChange={handleGenerateOrderChange}
                  placeholder="Optional"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={(e) => handleGenerateOrderModal(e)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    type="submit"
                    onClick={() => handleSubmitOrder()}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </CustomDialog>
      )}
    </>
  );
};

export default withRouter(MegaMenuSection);
