import { routeConstants } from "services/keyConstants";

let productBaseUrl = "/product";
const baseUrl = `/${routeConstants.ADVISORY_NOTICE_AND_RECALL}`;
const baseUrlNotice = `${baseUrl}/notice`;
const baseUrlFormat = `${baseUrl}/format`;

export const CREATER = `${baseUrl}/`;

export const GET_PRO_FORMAT_DATA = `${baseUrl}/pro-format/`;

export const GET_FORMAT_DATA = `${baseUrlFormat}/`;

export const POST_PRO_FORMAT_PRODUCT_RECALL_REGISTER = `${baseUrl}/pro-format`;

export const GET_RETURNED_GOODS_RECORDS = `${baseUrlFormat}/return-of-goods/`;

export const DOWNLOAD_DOCUMENT = `${baseUrlFormat}/download`;

export const CREATE_ADVISORY_NOTICE = `${baseUrlNotice}`;

export const GET_ALL_ADVISORY_NOTICE = `${baseUrlNotice}`;

export const GET_ALL_ADVISORY_NOTICE_BY_ID = `${baseUrlNotice}/`;

export const UPDATE_ADVISORY_NOTICE_BY_ID = `${baseUrlNotice}/`;

export const UPDATE_ADVISORY_NOTICE_SPECIFIC = `${baseUrlNotice}/specific/`;

export const GET_BY_YEAR = `${baseUrlNotice}/year-list`;

export const GET_PRODUCTS = `${productBaseUrl}/get-all`;

export const NOTICE_DOWNLOAD = `${baseUrlNotice}/download`;

const baseMrm = "/monitoring-and-measuring-of-product";
export const GET_SELECTED_PRODUCT_LIST = `${baseMrm}/get-list`;