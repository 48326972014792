import { commonRoutesConstants } from "services/keyConstants";

let correctiveBaseUrl = "/corrective-action";
let preventiveBaseUrl = "/preventive-action";

export const CREATE_CORRECTIVE_ACTION = `${correctiveBaseUrl}`;

export const GET_ALL_CORRECTIVE_ACTION = `${correctiveBaseUrl}`;

export const GET_ALL_CORRECTIVE_ACTION_BY_ID = `${correctiveBaseUrl}/`;

export const UPDATE_CORRECTIVE_ACTION_BY_ID = `${correctiveBaseUrl}/`;

export const UPDATE_CORRECTIVE_ACTION_SPECIFIC = `${correctiveBaseUrl}/specific/`;

export const CREATE_PREVENTIVE_ACTION = `${preventiveBaseUrl}`;

export const GET_ALL_PREVENTIVE_ACTION = `${preventiveBaseUrl}`;

export const GET_ALL_PREVENTIVE_ACTION_BY_ID = `${preventiveBaseUrl}/`;

export const UPDATE_PREVENTIVE_ACTION_BY_ID = `${preventiveBaseUrl}/`;

export const UPDATE_PREVENTIVE_ACTION_SPECIFIC = `${preventiveBaseUrl}/specific/`;

export const DOWNLOAD_CORRECTIVE_ACTION = `${correctiveBaseUrl}/download`;

export const DOWNLOAD_PREVENTIVE_ACTION = `${preventiveBaseUrl}/download`;

export const GET_BY_YEAR = `${correctiveBaseUrl}/${commonRoutesConstants.YEAR_LIST}`;

export const GET_BY_YEAR_PREVENTIVE_ACTION = `${preventiveBaseUrl}/${commonRoutesConstants.YEAR_LIST}`;
