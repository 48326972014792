import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

// render - pages
const PurchaseFormatDetail = Loadable(
  lazy(() => import("../PurchaseFormatDetail"))
);
const PurchaseChildFormatDetails = Loadable(
  lazy(() => import("../PurchaseChildFormatDetails"))
);
const PurchaseOrderFormat = Loadable(
  lazy(() => import("../PurchaseOrderFormat"))
);
const SupplierServiceProviderSelectionFormat = Loadable(
  lazy(() => import("../SupplierServiceProviderSelectionFormat"))
);
const IssueSlip = Loadable(lazy(() => import("../IssueSlip")));
const RawMaterialStockRegisters = Loadable(
  lazy(() => import("../RawMaterialStockRegisters"))
);
const SupplierEvaluationForm = Loadable(
  lazy(() => import("../SupplierEvaluationForm"))
);
const SubContractorAgreement = Loadable(
  lazy(() => import("../SubContractorAgreement"))
);
const IncomingInspectionReport = Loadable(
  lazy(() => import("../IncomingInspectionReport"))
);
const SupplierReEvaluationForm = Loadable(
  lazy(() => import("../SupplierReEvaluationForm"))
);
const SupplierAgreement = Loadable(lazy(() => import("../SupplierAgreement")));
const RequisitionSlip = Loadable(lazy(() => import("../RequisitionSlip")));
const ApprovedSupplierList = Loadable(
  lazy(() => import("../ApprovedSupplierList"))
);

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// ==============================|| PAGE ROUTES ||============================== //

const PurchaseRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.PURCHASE, null, [
      createRoute("format-details", <PurchaseFormatDetail />),
      createRoute("purchase-child-formats", <PurchaseChildFormatDetails />),
      createRoute("purchase-order", <PurchaseOrderFormat />),
      createRoute(
        "supplier-format",
        <SupplierServiceProviderSelectionFormat />
      ),
      createRoute("issue-slip", <IssueSlip />),
      createRoute("raw-material-stock-register", <RawMaterialStockRegisters />),
      createRoute("supplier-evaluation-form", <SupplierEvaluationForm />),
      createRoute("sub-contractor-agreement", <SubContractorAgreement />),
      createRoute("incoming-inspection-report", <IncomingInspectionReport />),
      createRoute("supplier-reevaluation-form", <SupplierReEvaluationForm />),
      createRoute("supplier-aggreement", <SupplierAgreement />),
      createRoute("requisition-slip", <RequisitionSlip />),
      createRoute("apporved-supplier-list", <ApprovedSupplierList />),
    ]),
  ]
);

export default PurchaseRoutes;
