const baseUrl = "/organogram";
const versionHtmlBaseUrl = "/version-html"
const qualityManualBaseUrl = "/quality-manual"
//organogram
export const GET_ORGANOGRAM = `${baseUrl}`;

export const ADD_NEW_DATA = `${baseUrl}/individual`;

export const GET_LEVEL = `${baseUrl}/get-level`;

export const GET_ORGANOGRAM_USERS = `${baseUrl}/fetch-users/`;

export const GET_ORGANOGRAM_DESIGNATIONS = `${baseUrl}/fetch-designation/`;
export const GET_USER_BY_DESIGNATION = `${baseUrl}/get-user-by-designation/`;

export const TRANSFER_RESPONSIBILITY = `${baseUrl}/transfer-responsibility/`;
export const TRANSFER_DESIGNATION = `${baseUrl}/transfer-designation/`;

export const ASSIGN_EXTRA_DESIGNATION = `${baseUrl}/assign-extra/`;

export const TRANSFER_EXTRA_DESIGNATION = `${baseUrl}/transfer-extra/`;
export const DOWNLOAD_RESPONSIBILITY = `${baseUrl}/download-responsibility`
export const GET_USERS = `${baseUrl}/get-users/`;
export const GET_USERS_WITHOUTAUTH = `${baseUrl}/get-users-withoutauth/`;
export const GET_RESPONSIBILITY_BY_TYPE = `${baseUrl}/get-responsibility/`;

export const VERSION_HTML = `${versionHtmlBaseUrl}/`

export const GET_QUALITY_MANUAL_BY_ID = `${qualityManualBaseUrl}/`

export const DOWNLOAD_ORGANOGRAM = `${baseUrl}/download-organogram`