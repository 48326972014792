import { lazy } from "react";
import * as url from './urlHelper'
// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";

const MasterValidationPlan = Loadable(
  lazy(() => import("../MasterValidationPlan"))
);
const MasterValidationPlanById = Loadable(
  lazy(() => import("../MasterValidationPlanById"))
);
const ProcessValidationFormat = Loadable(
  lazy(() => import("../ProcessValidationFormat"))
);
const ValidationList = Loadable(lazy(() => import("../ValidationList")));
const ValidationProtocolReport = Loadable(
  lazy(() => import("../ValidationProtocolReport"))
);
const DocumentDetails = Loadable(lazy(() => import("../DocumentDetails")));
const List = Loadable(lazy(() => import("../List")));
const YearList = Loadable(lazy(() => import("../YearList")));

const createRoute = (path, element, children) => ({ path, element, children });

const PVRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.PROCESS_VALIDATIONS}`, null, [
      createRoute("master-validation-plan", <MasterValidationPlan />),
      createRoute(
        "master-validation-plan-details",
        <MasterValidationPlanById />
      ),
      createRoute(
        "process-validation-format/:year",
        <ProcessValidationFormat />
      ),
      createRoute("validation", <ValidationList />),
      createRoute("validation-protocol-report", <ValidationProtocolReport />),
      createRoute("document-details", <DocumentDetails />),
      createRoute("list/:year", <List />),
      createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
        uri={url.GET_BY_YEAR}
        title={routeConstants.PROCESS_VALIDATIONS}
        isCreate={true}
        createRoute={{ path: `/${routeConstants.PROCESS_VALIDATIONS}/master-validation-plan` }}
        navigateToFolder={`/${routeConstants.PROCESS_VALIDATIONS}/list/`}
      />),
    ]),
  ]
);

export default PVRoutes;
