import React, { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import RecallModal from "../RecallModal";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "./urlHelper";

// Define lazy-loaded components

const ProductRecallRegister = Loadable(
  lazy(() => import("../ProductRecallRegister"))
);
const RecallList = Loadable(lazy(() => import("../RecallList")));
const RetunedGoodsRecords = Loadable(
  lazy(() => import("../RetunedGoodsRecords"))
);
const AdvisoryNotice = Loadable(lazy(() => import("../AdvisoryNotice")));

const AdvisoryNoticeList = Loadable(
  lazy(() => import("../AdvisoryNoticeList"))
);
const List = Loadable(lazy(() => import("../List")));

const YearList = Loadable(lazy(() => import("../YearList")));

const YearWiseDocument = Loadable(lazy(() => import("../YearWiseDocument")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const ARRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.ADVISORY_NOTICE_AND_RECALL}`, null, [
      createRoute("product-recall-register", <ProductRecallRegister />),
      createRoute("recall-list/:year", <RecallList />),
      createRoute("returned-goods-record", <RetunedGoodsRecords />),
      createRoute("advisory-notice", <AdvisoryNotice />),
      createRoute("advisory-list/:year", <AdvisoryNoticeList />),
      createRoute("list/:year", <List />),
      createRoute(
        commonRoutesConstants.YEAR_LIST,
        <CommonYearList
          uri={url.GET_BY_YEAR}
          title={routeConstants.ADVISORY_NOTICE_AND_RECALL}
          isExtraButtons={true}
          isRenderComponent={() => React.createElement(RecallModal)}
          extraButtonChildren={[
            {
              buttonTitle: "Create Advisory Notice",
              path: `/${routeConstants.ADVISORY_NOTICE_AND_RECALL}/advisory-notice`,
            },
          ]}
          navigateToFolder={`/${routeConstants.ADVISORY_NOTICE_AND_RECALL}/list/`}
        />
      ),
      
      createRoute(
        "year-wise-document/:documentName/:year",
        <YearWiseDocument />
      ),
    ]),
  ]
);

export default ARRoutes;
